<template>
  <div>
    <div>
      <div class="search-title background__blue_document">
        <p class="title-secondary fs-20 color__blue_main fw__bold">ファイル検索</p>
      </div>
      <div class="page-main-content is-filter p__bottom--0 p__top--40"
           @keyup.enter="searchDocuments()"
           :class="{'is-active': openSearch, 'cursor-pointer': !openSearch}" @click="toggleSearch(true)">
        <div class="search-button field">
          <div class="control has-icons-right">
            <input class="input input-modal" readonly
                   :placeholder="$t('search_documents.input.search.placeholder')"
            >
            <div class="icon is-small is-right icon-search">
              <div>
                <img width="19px" src="../../assets/svgs/SideBar/ic_search_home.svg"/>
              </div>
            </div>
          </div>
        </div>
        <div class="search">
          <div class="search-free-word m__bottom--12">
            <div class="columns modal-field-content d-flex is-align-items-flex-end" v-for="(e, i) in vuelidate.filter.free_word.$each.$iter"
                 :key="'free-word-' + i">
              <div class="column text-free ">
                <div class="free-word-search">
                  <label class="input-title fs-14 color__blue_button fw__bold"
                         v-if="i * 1 === 0">{{ $t('search_documents.input.free_word.label') }}</label>
                </div>
                <div>
                  <input class="input" :class="{'is-error': !e.text.maxLength && e.text.$error}"
                         v-model.trim="e.text.$model" type="text"
                         :placeholder="$t('search_documents.input.free_word.placeholder')">
                </div>
                <span class="error pt-1" v-if="!e.text.maxLength && e.text.$error">
                  {{ $t('search_documents.messages.validation.free_word_maxlength') }}
                </span>
              </div>
              <div class="column d-flex condition">
                <div class=" column is-8" v-if="i * 1 !== 0">
                  <div class="dropdown w--100 is-right is-up"
                       :id="'dropdown-free-word-condition-' + i"
                       v-click-out="()=>closeDropdown('dropdown-free-word-condition-' + i)">
                    <div class="dropdown-trigger w--100">
                      <button @click="toggleDropdown('dropdown-free-word-condition-' + i)"
                              aria-haspopup="true"
                              class="button w--100 is-justify-content-flex-start condition btn-and">
                        <span class="color__blue_button fs-14">{{ e.condition.$model || '' }}</span>
                        <span class="icon is-small color__blue_button">
                           <i aria-hidden="true" class="fas fa-angle-up"></i>
                        </span>
                      </button>
                    </div>
                    <div class="dropdown-menu w--100" role="menu">
                      <div class="dropdown-content">
                        <a :class="{'is-active': el.id === e.condition.$model}"
                           :key="'condition-' + i + '-' + index"
                           @click="getCondition(i, el, 'dropdown-free-word-condition-' + i)"
                           class="dropdown-item fs-14"
                           v-for="(el, index) in dropConditions">
                          {{ el.name }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-4 is-4-mobile is-4-tablet p__left--30 d-flex">
                  <button @click="addFreeWord()"
                          v-if="i * 1 === 0"
                          class="blob is-action is-half action-success m__right--10">
                    <img src="@/assets/svgs/ic_add_2.svg"/>
                  </button>
                  <button @click="removeFreeWord(i)"
                          v-if="i * 1 !== 0 || filter.free_word.length > 1"
                          class="blob is-action is-half action-danger m__right--10">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="search-select-muti">
            <div class="columns m--0 search-select d-flex">
              <div class="column is-half">
                <div class="p__top--0">
                  <div class="align-self-center">
                    <label class="input-title color__blue_button fs-14 fw__bold">{{ $t('search_documents.input.tag.label') }}</label>
                  </div>
                  <div>
                    <multiselect :list-data="tags"
                                 @submit-parent-form="searchDocuments()"
                                 :placeholder="$t('search_documents.input.tag.placeholder')"
                                 :selected="filter.tag_ids"
                                 @change="getFilterData($event, 'tag_ids')">
                    </multiselect>
                  </div>
                </div>

                <div class="p__top--16">
                  <div class="align-self-center">
                    <label class="input-title color__blue_button fs-14 fw__bold">{{ $t('search_documents.input.division.label') }}</label>
                  </div>
                  <div>
                    <multiselect :list-data="divisions"
                                 @submit-parent-form="searchDocuments()"
                                 :placeholder="$t('search_documents.input.division.placeholder')"
                                 :selected="filter.division_ids"
                                 @change="getFilterData($event, 'division_ids')">
                    </multiselect>
                  </div>
                </div>

                <div class="p__top--16" v-if="userInfo && userInfo.id !== 4">
                  <div class="align-self-center">
                    <label class="input-title color__blue_button fs-14 fw__bold">{{ $t('search_documents.input.owner.label') }}</label>
                  </div>
                  <div>
                    <multiselect :list-data="users"
                                 @submit-parent-form="searchDocuments()"
                                 :placeholder="$t('search_documents.input.owner.placeholder')"
                                 :selected="filter.owner_ids"
                                 @change="getFilterData($event, 'owner_ids')">
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="column column-right p__right--0">
                <div class="p__top--0">
                  <div class="align-self-center">
                    <label class="input-title color__blue_button fs-14 fw__bold">{{ $t('search_documents.input.branch.label') }}</label>
                  </div>
                  <div>
                    <multiselect :list-data="branches"
                                 @submit-parent-form="searchDocuments()"
                                 :placeholder="$t('search_documents.input.branch.placeholder')"
                                 :selected="filter.branch_ids"
                                 @change="getFilterData($event, 'branch_ids')">
                    </multiselect>
                  </div>
                </div>
                <div class="p__top--16">
                  <div class="align-self-center">
                    <label class="input-title color__blue_button fs-14 fw__bold">{{ $t('search_documents.input.office.label') }}</label>
                  </div>
                  <div>
                    <multiselect :list-data="offices"
                                 @submit-parent-form="searchDocuments()"
                                 :placeholder="$t('search_documents.input.office.placeholder')"
                                 :selected="filter.office_ids"
                                 @change="getFilterData($event, 'office_ids')">
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-field-content p__top--0">
            <div>
              <label class="input-title fs-14 color__blue_button fw__bold">{{ $t('search_documents.input.doc_type.label') }}</label>
            </div>
            <div class="width-checkbox m__bottom--37">
              <div class="is-flex" v-for="(item, index) in Math.ceil(documentTypes.length/4)" :key="index">
                <template v-for="(e, i) in documentTypes">
                  <label class="container-checkbox checkbox has-text-nowrap" :key="'document-type-' + i" v-if="i < item * 4 && i >= item * 4 - 4">
                    <span class="p__left--10 fs-14 color__gray_tree">{{ e.name }}</span>
                    <input type="checkbox" class="checkbox-type vertical-middle" :value="e.id" v-model="e.checked"
                           @change="getSelectedDocumentTypes()">
                    <span class="checkmark"></span>
                  </label>
                </template>
              </div>
            </div>
          </div>
          <div class="modal-field-content p__top--0 p__bottom--18">
            <div class="align-self-center">
              <label class="input-title fs-14 color__blue_button fw__bold">{{ $t('search_documents.input.date_create') }}</label>
            </div>
            <div class="columns m-0">
              <div class="column is-4 d-flex p__left--0 p__top--3 p__bottom--0">
                <div class="date-input">
                  <date-picker v-model="dateRanges.created_at_from" ref="created_at_from" :disabled-date="contractMax"
                               format="YYYY-MM-DD"
                               type="date" lang="ja" class="w--100 fw__bold">
                    <template slot="icon-calendar">
                      <img class="img-calendar" src="../../assets/svgs/ic_calendar.svg">
                    </template>
                  </date-picker>
                </div>
                <div class="range-date p__left--30 p__right--30 d-flex align-items-center">
                  <span class="color__white_pink"><img width="20px" src="../../assets/svgs/ic_date.svg"/></span>
                </div>
                <div class="date-input">
                  <date-picker v-model="dateRanges.created_at_to" ref="created_at_to" :disabled-date="contractMin"
                               format="YYYY-MM-DD"
                               type="date" lang="ja" class="w--100 fw__bold">
                    <template slot="icon-calendar">
                      <img class="img-calendar" src="../../assets/svgs/ic_calendar.svg">
                    </template>
                  </date-picker>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-field-content">
            <div class="align-self-center">
              <label class="input-title fs-14 color__blue_button fw__bold">{{ $t('search_documents.input.date_update') }}</label>
            </div>
            <div class="columns m-0">
              <div class="column is-4 d-flex p__left--0 p__top--3">
                <div class="date-input">
                  <date-picker v-model="dateRanges.updated_at_from" ref="updated_at_from"
                               :disabled-date="updatedMax" format="YYYY-MM-DD"
                               type="date" lang="ja" class="w--100 fw__bold">
                    <template slot="icon-calendar">
                      <img class="img-calendar" src="../../assets/svgs/ic_calendar.svg">
                    </template>
                  </date-picker>
                </div>
                <div class="range-date p__left--30 p__right--30 d-flex align-items-center">
                  <span class="color__white_pink"><img width="20px" src="../../assets/svgs/ic_date.svg"/></span>
                </div>
                <div class="date-input">
                  <date-picker v-model="dateRanges.updated_at_to" ref="updated_at_to" :disabled-date="updatedMin"
                               format="YYYY-MM-DD"
                               type="date" lang="ja" class="w--100 fw__bold">
                    <template slot="icon-calendar">
                      <img class="img-calendar" src="../../assets/svgs/ic_calendar.svg">
                    </template>
                  </date-picker>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="columns modal-buttons-footer m--0">
            <div
                class="column is-full has-text-centered-desktop has-text-centered-fullhd
                 has-text-centered-mobile has-text-centered-tablet btn-save-box">
              <button @click="searchDocuments()"
                      class="btn is-success close-modal btn-save c-pointer fs-16">
                {{ $t('users_management.buttons.search_user') }}
              </button>
            </div>
          </div>
          <div class="columns modal-foot-toggle m--0">
            <div @click.stop="toggleSearch()"
                 class="column is-full column-toggle-search d-flex is-justify-content-center align-items-center p--10">
              <span class="icon-close-search">
                <i class="fas fa-angle-up color__blue_main"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-result pt-0" v-if="isFiltered">
      <div class="page-main-content page-list-data m__top--20">
        <div class="columns d-flex m__top--0">
          <div
              class="column p__bottom--0 p__top--0 is-align-self-flex-end is-half-mobile is-half-desktop is-half-fullhd is-half-tablet is-half-widescreen is-half-touch">
            <span class="vertical-baseline-middle color__gray_text_1 fs-12">検索結果：全{{ paginate.total || 0 }}件</span>
          </div>
          <div
              class="column p__bottom--0 p__top--0 has-text-right align-items-center is-half-mobile is-half-desktop is-half-fullhd is-half-tablet is-half-widescreen is-half-touch">
            <span class="m__right--5 vertical-baseline-middle fs-12 color__gray_text_1">{{ $t('page.page-range') }}</span>
            <div class="dropdown is-right"
                 id="dropdown-pagination"
                 v-click-out="closeDropPageRange">
              <div class="dropdown-trigger">
                <button @click="toggleDropPageRange()"
                        aria-haspopup="true"
                        class="button btn-change-page-range p__top--0 p__bottom--0 p__left--10 vertical-baseline-middle">
                  <span class="w--80 has-text-centered p__right--5 fs-12 color__gray_text_1">{{ paginate.perPage }} 件</span>
                  <span class="icon is-small">
                     <img width="8px" src="../../assets/svgs/icon_drop.svg"/>
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <a :key="'page-range-' + i"
                     @click="changePageRange(e)"
                     class="dropdown-item p__right--10 fs-12 color__gray_text_1"
                     v-for="(e, i) in pageRanges">
                    {{ e }} 件
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column p__top--0">
            <div class="table-container data-table">
              <table class="table is-striped is-hoverable is-fullwidth">
                <thead>
                <tr>
                  <th class="input-title fs-12">{{ $t('search_documents.list.name') }}</th>
                  <th class="input-title fs-12">{{ $t('search_documents.list.office_name') }}</th>
                  <th class="input-title fs-12">{{ $t('search_documents.list.owner') }}</th>
                  <th class="input-title fs-12">{{ $t('search_documents.list.create_at') }}</th>
                  <th class="input-title fs-12">{{ $t('search_documents.list.updated_at') }}</th>
                  <th class="input-title input-tags fs-12">{{ $t('search_documents.list.tag') }}</th>

                  <th class="col-action"></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(e, i) in documentsClone">
                  <tr class="document-row cursor-pointer" :class="{'is-active': e.openChild}" @click="goToDetail(e)"
                      :key="'document-' + i">
                    <td class="name">{{ e.name }}</td>
                    <td class="office">{{
                        e.office && e.office.division.branch.name + ' > ' + e.office.division.name + ' > ' +
                        e.office.name
                      }}
                    </td>
                    <td class="owner">{{ e.owner && e.owner.name }}</td>
                    <td class="date-td">{{ momentFormat(e.created_at) }}</td>
                    <td class="date-td">{{ momentFormat(e.updated_at) }}</td>
                    <td class="tag-td">
                    <span class="tag m--5" v-for="(tag, index) in e.tags.slice(0,3)"
                          :key="'file-' + i + '-' + index">{{ tag.name }}
                    </span>
                      <span :class="{'d-none': e.tags.length < 3}">...</span>
                    </td>
                    <td class="action-td" @click.stop="toggleChild(e.id)">
                    <span class="title-table drop-document-1" v-if="e.files && e.files.length">
                      <span class="icon is-small icon-caret-down">
                        <img width="15px" height="8px" src="../../assets/svgs/ic_drop_2.svg">
                      </span>
                    </span>
                    </td>
                  </tr>
                  <template v-if="e.files && e.files.length">
                    <template v-if="e.openChild">
                      <tr class="file-row-1" :key="'file-' + i + '-' + index" v-for="(file, index) in e.files">
                        <td class="has-word-break" colspan="7">
                          <span class="pl-5 d-flex">
                            <img src="@/assets/svgs/Files/ic_doc.svg" class="icon-file m__right--10 m__left--20"
                                 v-if="file.file_format * 1 === 1">
                            <img src="@/assets/svgs/Files/ic_PDF.svg" class="icon-file m__right--10 m__left--20"
                                 v-if="file.file_format * 1 === 2">
                            <img src="@/assets/svgs/Files/ic_image.svg" class="icon-file m__right--10 m__left--20"
                                 v-if="file.file_format * 1 === 3">
                            <img src="@/assets/svgs/Files/ic_excel.svg" class="icon-file m__right--10 m__left--20"
                                 v-if="file.file_format * 1 === 4">
                            <img src="@/assets/svgs/Files/ic_PPTX.svg" class="icon-file m__right--10 m__left--20"
                                 v-if="file.file_format * 1 === 5">
                            <img src="@/assets/svgs/Files/ic_txt.svg" class="icon-file m__right--10 m__left--20"
                                 v-if="file.file_format * 1 === 6">
                            <img src="@/assets/svgs/Files/ic_Zip_1.svg" class="icon-file m__right--10 m__left--20"
                                 v-if="file.file_format * 1 === 7">
                            {{ file.original_name }}
                          </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
                </tbody>
                <tr v-if="!documentsClone || !documentsClone.length">
                  <td colspan="7" class="has-text-centered">{{ $t('target_not_found') }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="columns p__bottom--14">
          <div class="column is-full has-text-centered m__top--10">
            <pagination
                :current="paginate.currentPage"
                :per-page="paginate.perPage"
                :total="paginate.total"
                @change="changePage($event)">
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions"
import DocumentService from "@/services/DocumentService";
import i18n from "@/lang/i18n";
import {UserService} from "@/services";
import SearchService from "@/services/SearchService";
import {momentFormat} from "@/filters";
import findIndex from "lodash/findIndex";
import {maxLength} from "vuelidate/lib/validators";
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ja'
import {ACTION_SET_SEARCH_PARAMS} from "@/stores/common/actions";
import MixinService from "@/services/MixinService";

export default {
  name: "SearchDocuments",
  components: {DatePicker},
  data() {
    return {
      openSearch: true,
      isFiltered: false,
      paginate: {
        currentPage: null,
        lastPage: null,
        perPage: null,
        total: null
      },
      pageRanges: [10, 20, 50, 100],
      dropConditions: [
        {id: 'and', name: 'and'},
        {id: 'or', name: 'or'},
      ],
      tags: [],
      users: [],
      documentTypes: [],
      filter: {
        free_word: [
          {
            text: '',
            condition: 'or'
          },
        ],
        tag_ids: '',
        branch_ids: '',
        division_ids: '',
        office_ids: '',
        owner_ids: '',
        document_type_ids: '',
        updated_at_from: '',
        updated_at_to: '',
        created_at_from: '',
        created_at_to: '',
      },
      dateRanges: {
        updated_at_from: '',
        updated_at_to: '',
        created_at_from: '',
        created_at_to: '',
      },
      documents: [],
      documentsClone: [],
      submitAvailable: true,
      offices: [],
      officePlaceholder: i18n.t('users_management.labels.input_field.office.placeholder'),
      roles: [
        // {id: 1, name: 'System Admin'},
        {id: 2, name: 'Admin'},
        {id: 3, name: 'Executive'},
        {id: 4, name: 'Staff'},
      ],
      rolePlaceholder: i18n.t('users_management.labels.input_field.role.placeholder'),
      divisions: [],
      divisionPlaceholder: i18n.t('users_management.labels.input_field.division.placeholder'),
      branches: [],
      branchPlaceholder: i18n.t('users_management.labels.input_field.branch.placeholder'),
      modalOpened: false,
      isTablet: false,
      windowSize: window.innerWidth
    }
  },
  validations: {
    filter: {
      free_word: {
        $each: {
          text: {
            maxLength: maxLength(50)
          },
          condition: {}
        }
      }
    }
  },
  methods: {
    updatedMin(date) {
      return date < new Date(this.dateRanges.updated_at_from)
    },
    updatedMax(date) {
      return date > new Date(this.dateRanges.updated_at_to)
    },
    contractMin(date) {
      return date < new Date(this.dateRanges.created_at_from)
    },
    contractMax(date) {
      return date > new Date(this.dateRanges.created_at_to)
    },
    addFreeWord() {
      let freeWord = {
        text: '',
        condition: 'or'
      }
      this.filter.free_word = [...this.filter.free_word, ...[freeWord]]
    },
    removeFreeWord(i) {
      this.filter.free_word.splice(i, 1)
    },
    getCondition(index, event, dropdown) {
      this.filter.free_word[index].condition = event.id
      this.closeDropdown(dropdown)
    },
    async getUsers() {
      await UserService.listUsers(1).then((res) => {
        if (res && res.data) {
          this.users = res.data.users
        }
      }).catch((err) => {
        console.log(err)
        this.$toast.error(this.$t('setting_alert.messages.can_not_get_users'))
      })
    },
    async getTags() {
      await DocumentService.getTags()
          .then((res) => {
            if (res && res.data) {
              this.tags = res.data.tags
            }
          })
          .catch((err) => {
            console.log(err)
            this.$toast.error(i18n.t('documents.messages.validation.no_tags'))
          })
    },
    async getDocumentTypes() {
      await DocumentService.documentTypes(1)
          .then((res) => {
            if (res && res.data) {
              this.documentTypes = res.data.document_types
              let docTypes = [...this.filter.document_type_ids.split`,`.map(e => +e)]
              this.documentTypes.map((e) => {
                e.checked = docTypes.includes(e.id);
              })
            }
          })
          .catch((err) => {
            console.log(err)
            this.$toast.error(this.$t('documents.messages.validation.no_doc_type'))
          })
    },
    toggleChild(id) {
      let index = findIndex(this.documents, (e) => e.id === id)
      if (index >= 0) {
        this.documents[index].openChild = !this.documents[index].openChild
      }
      this.documentsClone = [...this.documents]
    },
    getFilterData(event, field) {
      let data = []
      event.map((e) => {
        data = [...data, ...[e.id]]
      })
      this.filter[field] = data.join(',')
    },
    getSelectedDocumentTypes() {
      let data = []
      this.documentTypes.map((e) => {
        if (e.checked) {
          data = [...data, ...[e.id]]
        }
      })
      this.filter.document_type_ids = data.join(',')
    },
    getDate(event, field) {
      this.filter[field] = event
    },
    closeDropPageRange() {
      this.$el.querySelector('#dropdown-pagination').classList.remove('is-active')
    },
    toggleDropPageRange() {
      this.$el.querySelector('#dropdown-pagination').classList.toggle('is-active')
    },
    changePageRange(range) {
      this.paginate.perPage = range
      this.closeDropPageRange()
      this.searchDocuments(1, this.paginate.perPage)
    },
    changePage(page) {
      this.searchDocuments(page, this.paginate.perPage)
    },
    momentFormat(date, format = 'YYYY-MM-DD') {
      return momentFormat(date, format)
    },
    goToDetail(el) {
      this.$router.push({
        name: el.document_type.type === 1 ? 'BtoB' : 'BtoC',
        params: {id: el.id},
        query: {sid: el.service_user && el.service_user.id}
      }, () => {
      })
    },
    async searchDocuments(page = 1, limit = this.paginate.perPage) {
      this.$refs.updated_at_from.closePopup()
      this.$refs.updated_at_to.closePopup()
      this.$refs.created_at_from.closePopup()
      this.$refs.created_at_to.closePopup()
      this.vuelidate.$touch()
      if (!this.vuelidate.$invalid && this.submitAvailable) {
        delete this.filter.free_word[0].condition
        Object.keys(this.dateRanges).map((e) => {
          this.filter[e] = this.dateRanges[e] ? this.momentFormat(this.dateRanges[e]) : ''
        })
        let data = {}
        Object.keys({...this.filter}).map((e) => {
          data[e] = this.filter[e]
        })
        data.page = page
        data.limit = limit
        this.$store.dispatch(ACTION_SET_SEARCH_PARAMS, {key: 'documents', data})
        localStorage.setItem('searchParams', JSON.stringify(data))
        this.submitAvailable = false
        await SearchService.list(page, limit, {...this.filter})
            .then((res) => {
              this.submitAvailable = true
              if (res && res.data) {
                this.documents = res.data.documents
                this.documents.map((e) => {
                  e.openChild = false
                })
                this.documentsClone = [...this.documents]
                this.isFiltered = true
                this.paginate = {
                  currentPage: res.data.current_page,
                  lastPage: res.data.last_page,
                  perPage: res.data.per_page * 1,
                  total: res.data.total,
                }
              }
              this.openSearch = false
            })
            .catch((err) => {
              this.submitAvailable = true
              this.isFiltered = true
              console.log(err)
              this.$toast.error('Can not search documents')
              this.openSearch = false
            })
      }
    },
    toggleSearch(body = false) {
      if (!body || (body && !this.openSearch)) {
        this.openSearch = !this.openSearch
      }
    },
    closeDropdown(id) {
      this.$nextTick(() => {
        if (this.$el.querySelector(`#${id}`)) {
          this.$el.querySelector(`#${id}`).classList.remove('is-active')
        }
      })
    },
    toggleDropdown(id) {
      this.$nextTick(() => {
        if (this.$el.querySelector(`#${id}`)) {
          this.$el.querySelector(`#${id}`).classList.toggle('is-active')
          this.$refs['dropdown-keyword'] && this.$refs['dropdown-keyword'].focus()
        }
      })
    },
    closeModal(id) {
      if (this.modalOpened) {
        this.$nextTick(() => {
          if (this.$el.querySelector(`#${id}`)) {
            this.$el.querySelector(`#${id}`).classList.remove('is-active')
          }
        })
        setTimeout(() => {
          this.modalOpened = false
        })
      }
    },
    toggleModal(id) {
      this.$nextTick(() => {
        if (this.$el.querySelector(`#${id}`)) {
          this.$el.querySelector(`#${id}`).classList.toggle('is-active')
        }
      })
    },
    dropDisplay(list, id) {
      let name = ''
      list.map((e) => {
        if (e.id === id) {
          name = e.name
        }
      })
      return name
    },

    // call API list
    async getData(type) {
      await MixinService[type](1)
          .then((res) => {
            if (res) {
              if (res.data[type].length) {
                this[type] = res.data[type]
                if (type === 'positions') {
                  this.positions.map((e) => {
                    if (e.role && e.role.id === 4) {
                      this.positionStaffs = [...this.positionStaffs, ...[e]]
                    }
                  })
                }
              }
            }
          })
          .catch((err) => {
            this.$toast.error(err.data.message)
          })
    },
    myEventHandler() {
      if (window.innerWidth <= 1024) {
        this.isTablet = true
      } else {
        this.isTablet = false
      }
      this.windowSize = window.innerWidth
    },
  },
  watch: {
    windowSize() {
      let page_content_main = this.$el.querySelector('.page-main-content')
      let search_button = this.$el.querySelector('.search-button')
      let padding = 120
      if (this.isTablet) {
        padding = 40
      }
      search_button.style.width = (page_content_main.clientWidth - padding + "px")
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    if (window.innerWidth <= 1024) {
      this.isTablet = true
    } else {
      this.isTablet = false
    }
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'search')
    localStorage.setItem('prev', 'search')
    this.paginate.perPage = 10
    this.getTags()
    this.getUsers()
    this.getDocumentTypes()
    this.getData('offices')
    this.getData('divisions')
    this.getData('branches')
    this.$nextTick(() => {
      // let params = this.$store.getters['searchParams'].documents
      let params = localStorage.getItem('searchParams') ? JSON.parse(localStorage.getItem('searchParams')) : {}
      if (params && Object.keys(params).length) {
        Object.keys(params).map((e) => {
          if (e !== 'page' && e !== 'limit') {
            this.filter[e] = params[e]
          }
          if (e === 'page') {
            this.paginate.currentPage = params[e]
          }
          if (e === 'limit') {
            this.paginate.perPage = params[e]
          }
        })
        this.dateRanges = {
          updated_at_from: this.filter.updated_at_from ? new Date(this.filter.updated_at_from) : '',
          updated_at_to: this.filter.updated_at_to ? new Date(this.filter.updated_at_to) : '',
          created_at_from: this.filter.created_at_from ? new Date(this.filter.created_at_from) : '',
          created_at_to: this.filter.created_at_to ? new Date(this.filter.created_at_to) : '',
        }
        this.searchDocuments()
      }
    })
  },
  mounted() {
    let page_content_main = this.$el.querySelector('.page-main-content')
    let search_button = this.$el.querySelector('.search-button')
    let home_page = document.querySelector('.home-page-main')
    home_page.classList.add('bg_none')
    let padding = 120
    if (this.isTablet) {
      padding = 40
    }
    search_button.style.width = (page_content_main.clientWidth - padding + "px")
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
}
</script>

<style lang="scss" scoped>
::v-deep {

}
.search-title {
  padding: 10px 60px;
  border-radius: 10px 10px 0 0;
}
.search-result {
  margin-top: 10px;
  .page-main-content {
    padding: 26px 60px;
    .columns {
      margin: 0 !important;
      .column {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}
button {
  &.is-action.action-success {
    border-radius: 5px !important;
    color: white !important;
    background-color: $blue_button;
    border:none;
    padding: 0 !important;
    box-shadow: none;
    margin-bottom: 3px;
    &:hover, &:focus {
      color: white !important;
      background-color: $blue_button;
      transition: all 0.2s ease-in-out;
      border: 1px solid $blue_button !important;
    }
  }
  &.button {
    border-radius: 4px !important;
    border: 1px solid $white_pink;
  }
  &.is-action.action-danger {
    border-radius: 4px !important;
    box-shadow: none;
  }
  &.btn-change-page-range {
    height: 20px !important;
    width: 80px !important;
    border-radius: 10px !important;
    &:focus{
      box-shadow: none !important;
      border-color: $blue_main;
    }
  }
}
input {
 &:hover {
   border-color: $blue_main !important;
   box-shadow: none !important;
 }
  &:focus {
    border-color: $blue_main !important;
    box-shadow: none !important;
  }
}
.btn-and {
  &:hover {
    border: 1px solid $blue_main !important;
    box-shadow: none !important;
  }
  &:focus {
    border: 1px solid $blue_main !important;
    box-shadow: none !important;
  }
}
@media screen and(max-width: 1366px) {
  .document-row {
    .name {
      max-width: 100px;
    }
    .office {
      max-width: 250px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: break-spaces;
      line-height: 50px;
    }
  }
}
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-type {
  position: absolute;
  opacity: 0 !important;
  cursor: pointer;
  height: 0 !important;
  width: 0 !important;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid $white_pink;
  border-radius: 5px !important;
}
/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: $blue_button;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
input {
  border: 1px solid $white_pink;
  height: 40px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  padding-left: 20px;
  color: #006FC3;
  font-weight: bold;
  &:hover {
    box-shadow: none;
  }
}
.search-select {
  .column {
    padding-left: 0px;
  }
  .column-right {
    padding-left: 30px;
  }
}
.width-checkbox {
  .is-flex {
    display: grid !important;
    grid-template-columns: 100px 100px 100px 100px;
    grid-column-gap: 30px;

  }
  .checkbox-type {
    width: 20px !important;
    border-radius: 5px !important;
    opacity: 0.5;
  }
}
.date-input {
  width: 160px;
  .img-calendar {
    width: 20px;
    height: 20px;
  }
}
.btn-save {
  width: 140px !important;
  height: 35px !important;
  border-radius: 18px !important;
  background-color: $blue_main !important;
  border: none !important;
  font-size: 16px !important;
}
.icon-search {
  padding: 5px;
  width: 50px !important;
  div {
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    background-color: $blue_main;
  }
}

//.search-select-muti {
//  .columns {
//    margin: 0px !important;
//    .column {
//      padding-top: 0px;
//      padding-left: 0px;
//
//    }
//  }
//}
.page-main-content {
  border-radius: 10px 10px 10px 10px;
  &.is-filter {
    max-height: 70px;
    border-radius: 0px 0px 10px 10px !important;
    max-width: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, max-width 0.3s ease-in-out, padding-left 0.3s linear, padding-right 0.3s linear;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;
    position: relative;

    .search-button {
      opacity: 1;
      transition: all 0.3s ease-in-out;
      position: absolute;
      top: 10px;
      color: $blue_main;
      input {
        background-color: $gray_dark !important;
        border-radius: 20px !important;
        height: 40px;
      }
    }
    .search {
      .columns {
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      ~ .columns {
        .column-toggle-search {
          svg {
            transform: rotateZ(0deg);
            transition: transform 0.3s ease-in-out;
          }
        }
      }
      .search-free-word {
        .columns {
          margin: 0px !important;
          .column {
            padding-top: 0px;
            padding-left: 0px;
            &.condition {
              &.column {
                padding-bottom: 0px;
              }
            }

          }
        }
      }
    }

    &.is-active {
      max-height: 1600px;
      max-width: 100%;
      transition: max-height 0.3s ease-in-out, max-width 0.3s ease-in-out, padding-left 0.3s linear, padding-right 0.3s linear;
      padding: 10px 60px;
      .search-button {
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }

      .search {
        .columns {
          visibility: visible;
          opacity: 1;
          transition: all 0.3s ease-in-out;
        }

        ~ .columns {
          .column-toggle-search {
          }
        }
      }
    }
  }
}
.font-14{
  font-size:14px !important
}
@media screen and (max-width: 768px) {
  .page-list-data {
    .columns {
      .column:first-child,
      .column:nth-child(2) {
        padding: 10px !important;
      }
    }
  }
}

.column-toggle-search {
  color: #808080;

  .line-toggle-search {
    background-color: #b5b5b5;
    width: 200px;
    margin: 6px 0;
  }

  &:hover {
    cursor: pointer;
    color: #c585b2 ;

    .line-toggle-search {
      background-color: #c585b2 ;
      box-shadow: 0 0 5px #c585b2 ;
    }
  }
}

.condition {
  border-color: #808080;
}

.blob {
  width: 34px;
  height: 34px;
  top: 1px;
  left: 1px;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;

  &:hover {
    cursor: pointer;
  }
}

table {
  .input-tags {
    width: 10%
  }

  .col-action {
    max-width: 20px !important;
  }
}

.search-free-word {
  min-height: 100px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none !important;
  }

  &:hover {
    //box-shadow: 0 6px 3px -3px #d8d6d6;
    &::-webkit-scrollbar {
      display: block !important;
    }

    .column-action {
      width: calc(100% - 15px);
    }
  }
;

  .modal-field-content:not(:first-child) {
    display: flex;
  }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) {
  button {
    &.is-action.action-success {
      padding: 0 !important;
    }

    &.is-action.action-danger {
      padding: 0 !important;
    }

    &.btn-change-page-range {
      padding: 0 0 0 10px !important
    }
  }
  .search-title {
    padding: 10px 20px;
  }
  .search-select-muti {
    margin-bottom: 26px;
  }
  .page-main-content {
    padding: 20px 20px !important;
    .columns {
      .column {
        padding-left: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        &.width-checkbox {
          .is-flex {
            display: grid !important;

            &.mt-3 {
              margin-top: 0 !important;
            }
          }
        }
      }
      .column-toggle-search {
        padding: 0px !important;
      }
      .btn-save-box {
        padding: 20px 0px !important;
      }
    }
    .search {
      .search-free-word {
        .columns {
          padding-bottom: 10px !important;
          .column {
            padding-top: 0px;
            padding-left: 0px;
            &.condition {
              &.column {
                padding-bottom: 0px;
              }
            }

          }
        }
      }
    }
  }
  .width-checkbox {
    .is-flex {
      display: grid !important;
      grid-template-columns: 90px 90px 90px 90px;
      grid-column-gap: 30px;

    }
  }
}

@media only screen and (min-device-width: 767px) {
  .page-range {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span.m__right--5 {
      padding-right: 1rem;
    }
  }
  .column.is-4, .column.is-4-tablet {
    flex: auto;
  }
}
</style>